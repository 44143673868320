import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import {v4 as uuidv4} from 'uuid';

export function getStringDateOrEmptyString(data: Date | null | undefined, format: string): string {
  const datePipe = new DatePipe('en-US');
  const tempName = datePipe.transform(data, format);
  return tempName ? tempName : '';
}

/**
 * Returns a date in format 'dd-mm-yyyy' or an empty string if the date is null
 * @param data
 */
export function getDateStringOrEmptyString(data: Date | null | undefined) {
  return getStringDateOrEmptyString(data, environment.DATE_FORMAT);
}

// Helper
const StringIsNotNumber = (value: any) => isNaN(Number(value));

// Turn enum into array
export function StringEnumToArray(enumeration: any) {
  return Object.keys(enumeration)
    .filter(StringIsNotNumber)
    .map(key => enumeration[key]);
}

export function get30DaysAgoDate(): Date{
  const todayDate = new Date();
  return new Date(new Date().setDate(todayDate.getDate() - 30));
}

export function getLabelFromEnum (enumkeys: any, labels: any, enumkey: any) {
  const key = Object.keys(enumkeys)[Object.values(enumkeys).indexOf(enumkey.toLowerCase())];
  return labels[key];
}

export function generateUuid() : string {
  return uuidv4();
}

export async function getBase64ImageFromURL(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}

export function dateToUnixTimestamp(date: Date) {
  return unixTimestampFromMs(date.getTime()); // seconds
}

export function unixTimestampToDate(unixTimestamp: number) {
  return new Date(unixTimestamp * 1000);
}

export function unixTimestampFromMs(timestampMs: number) {
  return Math.floor(timestampMs / 1000);
}

export function roundMax2Decimals(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371e3; // Raggio della Terra in metri

  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distanza in metri
  return distance;
}

export function formatDistance(distance: number) : string {
  if(!distance){
    return '';
  }

  if(distance < 1000){
    return `${roundMax2Decimals(distance)} m`;
  }

  const km = distance / 1000;
  return `${roundMax2Decimals(km)} Km`;
}

export function doesAStringIncludeAnotherCaseInsensitive(firstString: string, secondString: string): boolean {
  return firstString.toUpperCase().includes(secondString.toUpperCase());
}
