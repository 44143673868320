import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {getRoles} from '../../../auth/selectors/auth.selector';
import {map} from 'rxjs/operators';
import {AppPermissions} from '../../../user/enum/app-permissions.enum';
import {Observable} from "rxjs";

@Pipe({
  name: 'hasRole',
})
export class HasRolePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(requestedPermissions: string[] | string, excludeAdmin = false): Observable<boolean> {
    return this.store.select(getRoles).pipe(
      map(loggedRoles => {
        if (!requestedPermissions || requestedPermissions.length == 0) return true;
        if (loggedRoles?.includes(AppPermissions.SUPERADMIN)) return true;
        return this.userHasAtLeastOnePermission(loggedRoles, requestedPermissions);
      })
    );
  }

  userHasAtLeastOnePermission(userDistinctPermissions: string[], requestedPermissions: string[] | string): boolean {
    return !!(userDistinctPermissions && userDistinctPermissions.some(x => requestedPermissions.includes(x)));
  }
}
