export enum AppPermissions {
  SUPERADMIN = 'SUPERADMIN',
  S2S = 'S2S', // può usare le S2S API
  S2S_COURSES = 'S2S_COURSES', // può usare solo le S2S API relative ai corsi
  ROLE_BOARD_ADMIN = 'ROLE_BOARD_ADMIN', //  è una cassa edile quindi può accedere alla UI es. cebrescia
  SUPPORT = "SUPPORT", // è una cassa edile ma con qualche privilegio in più di supporto (impersonate temporaneo) es. cape
  BOARD_UPDATE = 'BOARD_UPDATE', // es. prevedi
  BOARD_DATA = 'BOARD_DATA', // utente con dati che arrivano da una cassa edile es. s2sbrescia
  CAM_COM = 'CAM_COM',
  CAN_UPDATE_EQUIPMENT = 'CAN_UPDATE_EQUIPMENT',
  CAN_UPDATE_DOCUMENTS = 'CAN_UPDATE_DOCUMENTS',
  CAN_SEE_ALL_CONSTRUCTIONS = 'CAN_SEE_ALL_CONSTRUCTIONS',
  CAN_UPDATE_EMPLOYEES = 'CAN_UPDATE_EMPLOYEES',
  CAN_CREATE_CONSTRUCTIONS = 'CAN_CREATE_CONSTRUCTIONS',
  CAN_UPDATE_MY_DATA = 'CAN_UPDATE_MY_DATA',
  CAN_UPDATE_ACCOUNTING_REPORTS = 'CAN_UPDATE_ACCOUNTING_REPORTS',
  GUEST = 'GUEST', // utente simile a ROLE_BOARD_ADMIN con solo i permessi di lettura
  CAN_UPDATE_ACTIVITIES = 'CAN_UPDATE_ACTIVITIES',
  CAN_HANDLE_MANAGEMENT_SYSTEMS = 'CAN_HANDLE_MANAGEMENT_SYSTEMS',
  CAN_SEE_DASHBOARDS = 'CAN_SEE_DASHBOARDS',
  CAN_ACCESS_CONTROL = 'CAN_ACCESS_CONTROL',
  CAN_MANAGE_ATTENDANCE_REGISTER = 'CAN_MANAGE_ATTENDANCE_REGISTER',
}

export function getEditablePermissions() {
  return [
    {permission: AppPermissions.CAN_UPDATE_MY_DATA, bool: `canUpdateMyData`},
    {permission: AppPermissions.CAN_UPDATE_EQUIPMENT, bool: `canUpdateMyEquipments`},
    {permission: AppPermissions.CAN_UPDATE_DOCUMENTS, bool: `canUpdateMyDocuments`},
    {permission: AppPermissions.CAN_UPDATE_EMPLOYEES, bool: `canUpdateMyEmployees`},
    {permission: AppPermissions.CAN_SEE_ALL_CONSTRUCTIONS, bool: `canSeeAllConstructions`},
    {permission: AppPermissions.CAN_CREATE_CONSTRUCTIONS, bool: `canCreateConstructions`},
    {permission: AppPermissions.CAN_UPDATE_ACCOUNTING_REPORTS, bool: `canUpdateAccountingReports`},
    {permission: AppPermissions.CAN_UPDATE_ACTIVITIES, bool: `canUpdateActivities`},
    {permission: AppPermissions.CAN_HANDLE_MANAGEMENT_SYSTEMS, bool: `canHandleManagementSystems`},
    {permission: AppPermissions.CAN_SEE_DASHBOARDS, bool: `canSeeDashboards`},
    {permission: AppPermissions.CAN_MANAGE_ATTENDANCE_REGISTER, bool: `canManageAttendanceRegister`},
  ]
}
